import React, { useEffect, useState } from "react";
import Footer from "../policies/footer";
import Header from "../policies/header";
import { useParams } from "react-router-dom";
import { formatDateWithTime } from "../helper/DateFormater";
import { Link } from "react-router-dom";
import TutorialServices from "./services";
import { MDBContainer } from "mdb-react-ui-kit";

const SingleTutuorials = () => {
  let param = useParams();
  console.log("useParams", param);
  const [data, setData] = useState([]);
  const [popular, setPopular] = useState([]);
  const [recents, setRecents] = useState([]);

  const getBlog = () => {
    TutorialServices.getTutorial(param.slug)
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getPopularBlogs = () => {
    TutorialServices.getPopularTutorials()
      .then((response) => {
        setPopular(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getRecentBlogs = () => {
    TutorialServices.getRecentTutorials()
      .then((response) => {
        setRecents(response.data.data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  useEffect(() => {
    getBlog();
    getPopularBlogs();
    getRecentBlogs();
  }, [param]);

  useEffect(() => {
    window.scrollTo(0, 100);
  }, []);
  console.log("data", data);
  return (
    <>
      <Header />
      <div class="page-title-area">
        <div class="d-table">
          <div class="d-table-cell">
            <div class="container">
              <div class="page-title-content">
                <h2>{data.title}</h2>
                <ul>
                  <li>
                    <a href="/tutorials">Home</a>
                  </li>
                  {/* <li>Single Blog</li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div class="default-shape">
          <div class="shape-1">
            <img src="/assets/img/shape/1.png" alt="image" />
          </div>

          <div class="shape-2 rotateme">
            <img src="/assets/img/shape/2.png" alt="image" />
          </div>

          <div class="shape-3">
            <img src="/assets/img/shape/3.svg" alt="image" />
          </div>

          <div class="shape-4">
            <img src="/assets/img/shape/4.svg" alt="image" />
          </div>

          <div class="shape-5">
            <img src="/assets/img/shape/5.png" alt="image" />
          </div>
        </div>
      </div>

      <section class="single-blog-area pt-100 pb-100">
        <div class="container">
          <div class="row">
            <div class="col-lg-8 col-md-12">
              <div class="blog-details-desc">
                <div class="article-image">
                  <MDBContainer>
                    <div className="ratio ratio-16x9">
                      <iframe
                        src={data.link}
                        title={data.title}
                        allowfullscreen
                      ></iframe>
                    </div>
                  </MDBContainer>
                </div>
                <div class="article-content">
                  <div class="entry-meta">
                    <ul>
                      <li>
                        <span>Posted On:</span>
                        <a href="">{formatDateWithTime(data?.createdOn)}</a>
                      </li>
                      <li>
                        <span>Posted By:</span>
                        <a href="">{data.username}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  style={{ marginTop: 10 }}
                  dangerouslySetInnerHTML={{ __html: data.content }}
                ></div>
              </div>
            </div>

            <div class="col-lg-4 col-md-12">
              <aside class="widget-area" id="secondary">
                {/* <section class="widget widget_search">
                  <form class="search-form search-top">
                    <label>
                      <span class="screen-reader-text">Search for:</span>
                      <input
                        type="search"
                        class="search-field"
                        placeholder="Search..."
                      />
                    </label>
                    <button type="submit">
                      <i class="fa fa-search"></i>
                    </button>
                  </form>
                </section> */}

                <section class="widget widget_colugo_posts_thumb">
                  <h3 class="widget-title">Popular Posts</h3>
                  {popular?.map((item) => (
                    <article class="item">
                      <a href="#" class="thumb">
                        <span class="fullimage cover bg1" role="img"></span>
                      </a>
                      <div class="info">
                        <time class="2021-06-30">
                          {formatDateWithTime(item?.createdOn)}
                        </time>
                        <h4 class="title usmall">
                          <Link to={`/blog/${item.slug}`}>{item?.title}</Link>
                        </h4>
                      </div>
                    </article>
                  ))}
                </section>

                {/* <section class="widget widget_categories">
                  <h3 class="widget-title">Categories</h3>
                  <ul>
                    <li>
                      <a href="">Business</a>
                    </li>
                    <li>
                      <a href="">Privacy</a>
                    </li>
                    <li>
                      <a href="">Technology</a>
                    </li>
                    <li>
                      <a href="">Tips</a>
                    </li>
                    <li>
                      <a href="">Uncategorized</a>
                    </li>
                  </ul>
                </section> */}

                {/* <section class="widget widget_recent_comments">
                  <h3 class="widget-title">Recent Comments</h3>
                  <ul>
                    <li>
                      <span class="comment-author-link">
                        <a href="">A WordPress Commenter</a>
                      </span>
                      on
                      <a href="">Hello world!</a>
                    </li>
                    <li>
                      <span class="comment-author-link">
                        <a href="">Colugo</a>
                      </span>
                      on
                      <a href="">Hello world!</a>
                    </li>
                    <li>
                      <span class="comment-author-link">
                        <a href="">Wordpress</a>
                      </span>
                      on
                      <a href="">Hello world!</a>
                    </li>
                    <li>
                      <span class="comment-author-link">
                        <a href="">A WordPress Commenter</a>
                      </span>
                      on
                      <a href="">Hello world!</a>
                    </li>
                    <li>
                      <span class="comment-author-link">
                        <a href="">Colugo</a>
                      </span>
                      on
                      <a href="">Hello world!</a>
                    </li>
                  </ul>
                </section> */}

                <section class="widget widget_recent_entries">
                  <h3 class="widget-title">Recent Posts</h3>
                  <ul>
                    {recents?.map((item) => (
                      <li>
                        <Link to={`/blog/${item.slug}`}>{item.title}</Link>
                      </li>
                    ))}
                  </ul>
                </section>

                {/* <section class="widget widget_meta">
                  <h3 class="widget-title">Meta</h3>
                  <ul>
                    <li>
                      <a href="">Log in</a>
                    </li>
                    <li>
                      <a href="">
                        Entries
                        <abbr title="Really Simple Syndication">RSS</abbr>
                      </a>
                    </li>
                    <li>
                      <a href="">
                        Comments
                        <abbr title="Really Simple Syndication">RSS</abbr>
                      </a>
                    </li>
                    <li>
                      <a href="">WordPress.org</a>
                    </li>
                  </ul>
                </section> */}

                {/* <section class="widget widget_archive">
                  <h3 class="widget-title">Archives</h3>
                  <ul>
                    <li>
                      <a href="">May 2021</a>
                    </li>
                    <li>
                      <a href="">April 2021</a>
                    </li>
                    <li>
                      <a href="">June 2021</a>
                    </li>
                  </ul>
                </section> */}

                {/* <section class="widget widget_tag_cloud">
                  <h3 class="widget-title">Tags</h3>
                  <div class="tagcloud section-bottom">
                    <a href="">
                      IT
                      <span class="tag-link-count"> (3)</span>
                    </a>
                    <a href="">
                      Fria
                      <span class="tag-link-count"> (3)</span>
                    </a>
                    <a href="">
                      Games
                      <span class="tag-link-count"> (2)</span>
                    </a>
                    <a href="">
                      Fashion
                      <span class="tag-link-count"> (2)</span>
                    </a>
                    <a href="">
                      Travel
                      <span class="tag-link-count"> (1)</span>
                    </a>
                    <a href="">
                      Smart
                      <span class="tag-link-count"> (1)</span>
                    </a>
                    <a href="">
                      Marketing
                      <span class="tag-link-count"> (1)</span>
                    </a>
                    <a href="">
                      Tips
                      <span class="tag-link-count"> (2)</span>
                    </a>
                  </div>
                </section> */}
              </aside>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      {/* 
      <div class="subscribe-area ptb-100">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
              <div class="subscribe-content">
                <h2>Subscribe for our Newsletter</h2>
              </div>
            </div>

            <div class="col-lg-6 col-md-6">
              <form class="newsletter-form">
                <input
                  type="email"
                  class="input-newsletter"
                  placeholder="Enter your Email"
                  name="EMAIL"
                  required
                  autocomplete="off"
                />
                <button type="submit">Subscribe Now</button>

                <div id="validator-newsletter" class="form-result"></div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};
export default SingleTutuorials;
