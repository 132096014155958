import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getSubscriptionData = () => {
  return http.get(`company/worker/sharecount`, {
    headers: {
      ...authHeader(),
    },
  });
};
const getInvoiceData = (page, pageSize, userId, sortBy, sortDir) => {
  debugger;
  return http.get(
    `payment/company?userId=${userId}&pageNumber=${page}&pageSize=${pageSize}&sortBy=${sortBy}&sortDir=${sortDir}`,
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const getCompanyCurrentSubscription = (userId) => {
  return http.get(`/user/user/current/sub/${userId}`, {
    headers: {
      ...authHeader(),
    },
  });
};

const renewalPlan = (id) => {
  return http.post(`/user/subscription/renewal/${id}`);
};

const upgradeSubPlan = (data) => {
  debugger;
  return http.post(`/user/subscription/upgrade`, data);
};
const SubscriptionServices = {
  getSubscriptionData,
  getInvoiceData,
  getCompanyCurrentSubscription,
  renewalPlan,
  upgradeSubPlan,
};

export default SubscriptionServices;
