import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const getWorkerRequest = (timeZone) => {
  return http.get(`request/company/worker/list?timezoneId=${timeZone}`, {
    headers: {
      ...authHeader(),
    },
  });
};
const workerAprroval = (requestId, status) => {
  return http.put(
    `request/worker/status?requestId=${requestId}&status=${status}`,
    {},
    {
      headers: {
        ...authHeader(),
      },
    }
  );
};

const RequestApi = {
  getWorkerRequest,
  workerAprroval,
};

export default RequestApi;
