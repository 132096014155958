import { useEffect, useMemo, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { json, useLocation, useNavigate } from "react-router-dom";
import Worker from "./services";
import { jwtDecode } from "jwt-decode";
import { Box, Button, CircularProgress } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import "./style.css";
import LinearProgress from "@mui/material/LinearProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const EditWorker = () => {
  const { t } = useTranslation();
  const jwt = jwtDecode(localStorage.getItem("token"));
  const [load, setLoad] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("error");
  const navigate = useNavigate();
  const scriptLoad = useRef(false);
  const location = useLocation();
  console.log("location_12345", location);

  useEffect(() => {
    if (!scriptLoad.current) {
      var script6 = document.createElement("script");
      var script6Code = `  $('.fancy-file-upload').FancyFileUpload({
                params: {
                    action: 'fileuploader'
                },
                maxfilesize: 1000000
            });
        
            $(document).ready(function () {
                $('#image-uploadify').imageuploadify();
            })
        `;
      script6.innerHTML = script6Code;

      document.body.appendChild(script6);

      scriptLoad.current = true;
    }
  }, []);

  const [languages, setLanguages] = useState([]);
  console.log("languages", languages);
  function getlanguages() {
    Worker.languages().then((respons) => {
      setLanguages(respons.data.data);
    });
  }

  const [skill, setSkill] = useState([]);

  function getSkill() {
    Worker.workerskill(jwt.id).then((respons) => {
      setSkill(respons.data.data);
    });
  }
  useEffect(() => {
    getlanguages();
    getSkill();
  }, []);
  const [show, setShow] = useState(false);
  const [fileName, setFileName] = useState("");
  const [validityDate, setValidityDate] = useState("");
  const [fileItem, setFileItem] = useState();
  const [certificatesList, setCertificatesList] = useState([]);

  const workerData = {
    name:
      location.state !== null
        ? location.state.fullName !== undefined
          ? location.state.fullName
          : location.state.name
        : "",
    email:
      location.state !== null
        ? location.state.emailAddress !== undefined
          ? location.state.emailAddress
          : location.state.email
        : "",
    phoneNo: location.state !== null ? location.state.phoneNo : "",
    address:
      location.state !== null
        ? location.state.userProfile.address !== undefined
          ? location.state.userProfile.address
          : location.state.userProfile.address
        : "",
    citizenship:
      location.state !== null
        ? location.state.citizenship !== undefined
          ? location.state.citizenship
          : location.state.citizenship
        : "",
    ssn: location.state !== null ? location.state.ssn : "",
    companyId: jwt.companyId,
    employeeId:
      location.state !== null
        ? location.state.userProfile.employeeId !== undefined
          ? location.state.userProfile.employeeId
          : location.state.userProfile.employeeId
        : "",
    unionMembership:
      location.state !== null
        ? location.state.userProfile.unionMembership !== undefined
          ? location.state.userProfile.unionMembership
          : location.state.userProfile.unionMembership
        : "",
    employmentType:
      location.state !== null
        ? location.state.userProfile.AddWorkeremploymentType !== undefined
          ? location.state.userProfile.employmentType
          : location.state.userProfile.employmentType
        : "",
    skillId:
      location.state !== null ? location.state.userProfile.skills.id : "",
    payRate:
      location.state !== null
        ? location.state.userProfile.payRate !== undefined
          ? location.state.userProfile.payRate
          : location.state.userProfile.payRate
        : "",
    emergencyNumber:
      location.state !== null ? location.state.userProfile.emergencyNumber : "",

    languageSkills:
      location.state !== null ? location.state.languageSkills : "",
    bloodType:
      location.state !== null
        ? location.state.userProfile.bloodType !== undefined
          ? location.state.userProfile.bloodType
          : location.state.userProfile.bloodType
        : "",
    allergiesName:
      location.state !== null
        ? location.state.userProfile.allergiesName !== undefined
          ? location.state.userProfile.allergiesName
          : location.state.userProfile.allergiesName
        : "",
    holderName:
      location.state !== null
        ? location.state.userProfile.bankDetails.holderName !== undefined
          ? location.state.userProfile.bankDetails.holderName
          : location.state.userProfile.bankDetails.holderName
        : "",
    accountNumber:
      location.state !== null
        ? location.state.userProfile.bankDetails.accountNumber !== undefined
          ? location.state.userProfile.bankDetails.accountNumber
          : location.state.userProfile.bankDetails.accountNumber
        : "",
    bankName:
      location.state !== null
        ? location.state.userProfile.bankDetails.bankName !== undefined
          ? location.state.userProfile.bankDetails.bankName
          : location.state.userProfile.bankDetails.bankName
        : "",

    accountType:
      location.state !== null
        ? location.state.userProfile.bankDetails.accountType !== undefined
          ? location.state.userProfile.bankDetails.accountType
          : location.state.userProfile.bankDetails.accountType
        : "",
    dob:
      location.state !== null
        ? location.state.dob !== undefined
          ? location.state.dob
          : location.state.dob
        : "",
    hireDate:
      location.state !== null
        ? location.state.userProfile.hireDate !== undefined
          ? location.state.userProfile.hireDate
          : location.state.userProfile.hireDate
        : "",
    imageUrl:
      location.state !== null
        ? location.state.imageUrl !== undefined
          ? location.state.imageUrl
          : location.state.imageUrl
        : "",
    image:
      location.state !== null
        ? location.state.imageName !== undefined
          ? location.state.imageName
          : location.state.imageName
        : "",
    ibanNumber:
      location.state !== null
        ? location.state.userProfile.bankDetails.ibanNumber !== undefined
          ? location.state.userProfile.bankDetails.ibanNumber
          : location.state.userProfile.bankDetails.ibanNumber
        : "",
    certificateRequest: [],
  };

  const errorData = {
    name: "",
    email: "",
    phoneNo: "",
    address: "",
    citizenship: "",
    ssn: "",
    companyId: jwt?.companyId,
    employeeId: "",
    unionMembership: "",
    employmentType: "",
    skillId: "",
    payRate: "",
    emergencyNumber: "",
    languageSkills: [],
    bloodType: "A+",
    allergiesName: "",
    holderName: "",
    bankName: "",
    accountNumber: "",
    accountType: "",
    dob: "",
    hireDate: "",
    image: "",
    ibanNumber: "",
    certificateRequest: [],
    validityDate: "",
  };

  // Create refs for the form fields
  const formRefs = {
    name: useRef(),
    email: useRef(),
    phoneNo: useRef(),
    address: useRef(),
    citizenship: useRef(),
    ssn: useRef(),
    employeeId: useRef(),
    unionMembership: useRef(),
    employmentType: useRef(),
    skillId: useRef(),
    payRate: useRef(),
    emergencyNumber: useRef(),
    languageSkills: useRef(),
    bloodType: useRef(),
    allergiesName: useRef(),
    holderName: useRef(),
    bankName: useRef(),
    accountNumber: useRef(),
    accountType: useRef(),
    dob: useRef(),
    hireDate: useRef(),
    image: useRef(),
    ibanNumber: useRef(),
  };

  const [errors, setErrors] = useState(errorData);

  const [data, setData] = useState(workerData);

  // useEffect(() => {
  //     if (location.state !== null) {
  //         console.log("companyId", location.state.companyResponse.companyId, jwt.companyId)
  //         setData({
  //             ...data,
  //             name: location.state.fullName,
  //             email: location.state.emailAddress,
  //             phoneNo: location.state.phoneNo,
  //             ssn: location.state.ssn,
  //             companyId: location.state.companyResponse.companyId,
  //         })
  //     }
  // }, [])
  //Create Form Data
  // const formData = new FormData()
  // for (const key in data) {
  //     if (Array.isArray(data[key])) {
  //         data[key].map((item) => {
  //             formData.append(key, item);
  //         })
  //     } else {
  //         formData.append(key, data[key]);
  //     }

  // }

  const [progress, setProgress] = useState(0);
  const [showBar, setShowBar] = useState(false);
  const [getFileName, setGetFileName] = useState("");
  const [isImageChanges, setImageChanges] = useState(true);
  const saveBtn = useRef(null);
  useEffect(() => {
    if (
      saveBtn.current &&
      getFileName === "" &&
      fileName === "" &&
      validityDate === ""
    ) {
      saveBtn.current.disabled = true;
      console.log("saveBtn if", fileName);
    } else if (getFileName !== "" && fileName !== "" && validityDate !== "") {
      console.log("saveBtn else", fileName);

      saveBtn.current.disabled = false;
    }
    if (saveBtn.current && fileName === "") {
      saveBtn.current.disabled = true;
    }
  }, [show, getFileName, fileName, validityDate]);

  async function uploadDoc(type, datas) {
    // console.log("saveBtn", saveBtn)
    setGetFileName("");
    setShowBar(true);
    setProgress(0);
    const config = {
      onUploadProgress: function (progressEvent) {
        var percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        // console.log("percentCompleted", percentCompleted, progressEvent.loaded, progressEvent.total, progressEvent)
      },
    };
    const uploadFile = new FormData();
    uploadFile.append("multipartFile", datas);
    if (type == "doc") {
      Worker.fileUpload(uploadFile, config).then((respon) => {
        setShowBar(false);
        // console.log(respon)
        setGetFileName(respon.data.message);
      });
    } else {
      const respon = await Worker.imgeUpload(uploadFile, config);
      setShowBar(false);
      setImageChanges(false);
      setData((prevData) => {
        return { ...prevData, image: respon.data.message };
      });
    }
  }

  function addCertificate() {
    // Update certificatesList state
    setCertificatesList((prevCertificates) => [
      ...prevCertificates,
      {
        certificateName: fileName,
        filename: getFileName,
        validityDate: validityDate,
      },
    ]);

    // Update data state
    setData((prevData) => {
      const certificateRequestArray = Array.isArray(prevData.certificateRequest)
        ? prevData.certificateRequest
        : [];
      return {
        ...prevData,
        certificateRequest: [
          ...certificateRequestArray,
          {
            certificateName: fileName,
            filename: getFileName,
            validityDate: validityDate,
          },
        ],
      };
    });
    saveBtn.current.disabled = true;
    setFileName("");
    setValidityDate("");
    if (document.querySelector(".drop-zone__thumb") !== null) {
      console.log(document.querySelector(".drop-zone__thumb"));
      document.querySelector(".drop-zone__thumb").remove();
    }
  }

  function removeCertificate(items) {
    // Filter out the item to be removed from certificatesList
    const updatedCertificatesList = certificatesList.filter(
      (item) => !item.filename.includes(items.filename)
    );

    // Update certificatesList state
    setCertificatesList(updatedCertificatesList);

    // Update data state
    setData((prevData) => ({
      ...prevData,
      certificateRequest: prevData.certificateRequest.filter(
        (item) => !item.filename.includes(items.filename)
      ),
    }));
  }

  function handleDateChange(e) {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0); // Remove time part for comparison

    if (selectedDate < currentDate) {
      setErrors({
        ...errors,
        validityDate: "Validity date cannot be in the past.",
      });
    } else {
      setErrors({ ...errors, validityDate: "" });
      setValidityDate(e.target.value);
    }
  }

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  const minDate = new Date("1900-01-01");

  // function setValue(event) {
  //   const { name, value } = event.target;
  //   const phoneRegex = /^\+?[0-9\b]+$/;

  //   if (
  //     name === "phoneNo" ||
  //     name === "emergencyNumber" ||
  //     name === "accountNumber" ||
  //     name === "payRate"
  //   ) {
  //     // Validate phone numbers and similar fields
  //     if (phoneRegex.test(value) || value === "") {
  //       setData({ ...data, [name]: value });
  //     }
  //   } else if (name === "dob") {
  //     // Validate the date of birth field
  //     const selectedDate = new Date(value);

  //     if (selectedDate >= minDate && selectedDate <= eighteenYearsAgo) {
  //       setData({ ...data, [name]: value });
  //     } else {
  //       setMessage("Please select a date between 1900-01-01 and 18 years ago.");
  //       setSeverity("error");
  //       setOpen(true);
  //       setData({ ...data, [name]: "" }); // Reset the field if the date is invalid
  //     }
  //   } else {
  //     // For other fields, just update the state
  //     setData({ ...data, [name]: value });
  //   }
  // }

  function setValue(event) {
    debugger;
    const { name, value } = event.target;
    const phoneRegex = /^\+?[0-9\b]*$/;
    const ssnRegex = /^[0-9-]*$/; // Regex for digits and hyphens
    const minLength = 8;
    console.log(name, value); // Debugging line

    if (name === "phoneNo" || name === "emergencyNumber") {
      // Validate phone numbers and similar fields
      if (phoneRegex.test(value) || value === "") {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Invalid phone number format.",
        }));
        setMessage("Invalid phone number format.");
        setSeverity("error");
        setOpen(true);
      }
    }
    if (name === "payRate") {
      // Validate phone numbers and similar fields
      if (phoneRegex.test(value) || value === "") {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter digit only.",
        }));
        // setMessage("Invalid phone number format.");
        // setSeverity("error");
        // setOpen(true);
      }
    } else if (name === "dob") {
      // Validate the date of birth field
      const selectedDate = new Date(value);
      if (selectedDate >= minDate && selectedDate <= eighteenYearsAgo) {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setMessage("Please select a date between 1900-01-01 and 18 years ago.");
        setSeverity("error");
        setOpen(true);
        setData({ ...data, [name]: "" }); // Reset the field if the date is invalid
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Date must be between 1900-01-01 and 18 years ago.",
        }));
      }
    } else if (name === "ibanNumber") {
      debugger;
      const maxLength = 34;
      const ibanRegex = /^[A-Z]{2}\d{2}[A-Z\d]{1,30}$/i; // IBAN regex
      // Allow input up to the maximum length
      if (value.length <= maxLength) {
        setData({ ...data, [name]: value });

        // Validate IBAN format
        if (!ibanRegex.test(value)) {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "Invalid IBAN format.",
          }));
        } else if (value.length < 15) {
          // Minimum IBAN length (based on the shortest valid IBAN)
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "IBAN is too short.",
          }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: "",
          }));
        }
      }
    } else if (name === "hireDate") {
      // Validate the hire date field
      const selectedDate = new Date(value);
      const minDate = new Date("1900-01-01");
      const today = new Date();

      if (selectedDate >= minDate && selectedDate <= today) {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Hire date must be between January 1, 1900, and today.",
        }));
        setMessage("Hire date must be between January 1, 1900, and today.");
        setSeverity("error");
        setOpen(true);
        setData({ ...data, [name]: "" }); // Reset the field if the date is invalid
      }
    } else if (name === "accountNumber") {
      if (phoneRegex.test(value) || value === "") {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "Please enter digit only.",
        }));
        // setMessage("Invalid phone number format.");
        // setSeverity("error");
        // setOpen(true);
      }
    } else if (name === "ssn") {
      // Validate Social Security Number (SSN) field
      if (ssnRegex.test(value) || value === "") {
        setData({ ...data, [name]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [name]: "" })); // Clear error if valid
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [name]: "SSN can only contain digits and hyphens.",
        }));
        setMessage("SSN can only contain digits and hyphens.");
        setSeverity("error");
        setOpen(true);
      }
    } else {
      // For other fields, just update the state
      setData({ ...data, [name]: value });
    }
  }

  //Update Worker ID
  function UpdateWorker() {
    debugger;
    const userId = location.state.userId;
    setLoad(true);
    console.log("update", JSON.stringify(data));
    if (isImageChanges) {
      setData({ ...data, image: location.state.imageName });
    }
    Worker.updateWorker(userId, data)
      .then((response) => {
        setSeverity("success");
        setOpen(true);
        setMessage(response.data.message);
        setLoad(false);
        setErrors({});
      })
      .catch((error) => {
        debugger;
        const errorResponse = "";
        if (error.response) {
          const { status, data } = error.response;
          if (status === 401) {
            setMessage(data.message);
          } else if (error.response && error.response.status === 400) {
            const errorResponse = error.response.data;
            if (errorResponse.errorState) {
              setErrors({});
              let firstErrorField = null;
              Object.keys(errorResponse.errorState).forEach((key, index) => {
                const errorMessage = errorResponse.errorState[key][0];
                setErrors((prevErrors) => ({
                  ...prevErrors,
                  [key]: errorMessage,
                }));

                // Set the first error field reference
                if (index === 0) {
                  firstErrorField = key;
                }
              });

              // Auto-focus the first error field
              if (firstErrorField && formRefs[firstErrorField].current) {
                debugger;
                formRefs[firstErrorField].current.focus();
                formRefs[firstErrorField].current.scrollIntoView({
                  behavior: "smooth",
                  block: "center",
                });
              }
            } else {
              setMessage("The request is invalid.");
              setSeverity("error");
              setOpen(true);
            }
          } else if (
            status === 500 ||
            status === 403 ||
            error.response.status === 400
          ) {
            setMessage(errorResponse.error);
          }
        } else {
          setMessage("The request is invalid.");
        }
        setMessage("The request is invalid.");
        setSeverity("error");
        setOpen(true);
        setLoad(false);
      });
  }

  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    debugger;
    const form = event.currentTarget;
    console.log(form);

    // Additional custom validation
    // const emailInput = form.elements['email']; // Assuming your email input has the name 'email'
    // const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    // if (!emailRegex.tiest(emailInput.value)) {
    //     // If the emal doesn't match the regex pattern
    //     emailInput.setCustomValidity('Please enter a valid email address.');
    // } else {
    //     emailInput.setCustomValidity('');
    // }

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      console.log(
        data.name !== "",
        data.email !== "",
        data.phoneNo !== "",
        data.address !== "",
        data.citizenship !== "",
        data.ssn !== "",
        data.employeeId !== "",
        data.unionMembership !== "",
        data.employmentType !== "",
        data.payRate !== "",
        data.emergencyNumber !== "",
        data.licenseDocs !== "",
        data.languageSkills !== "",
        data.bloodType !== "",
        data.allergiesName !== "",
        data.holderName !== "",
        data.bankName !== "",
        data.accountNumber !== "",
        data.branchAddress !== "",
        data.accountType !== "",
        data.dob !== "",
        data.hireDate !== "",
        data.image !== ""
      );

      UpdateWorker();
    }

    setValidated(true);
  };

  //upload Profile Picture
  useEffect(() => {
    setTimeout(() => {
      const inputFile = document.querySelector("#picture__input");
      const pictureImage = document.querySelector(".picture__image");
      const pictureImageTxt = t("Choose a Profile Picture");
      pictureImage.innerHTML = pictureImageTxt;
      inputFile.addEventListener("change", function (e) {
        const inputTarget = e.target;
        const file = inputTarget.files[0];

        const allowedExtensions = ["jpg", "jpeg", "png"];
        const fileExtension = file.name.split(".").pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
          setMessage("Only jpg, jpeg, and png are allowed.");
          setSeverity("error");
          setOpen(true);
          return;
        }
        // setData({ ...data, images: file })
        //data.image = file
        uploadDoc("photo", file);
        // console.log(formData)
        if (file) {
          const reader = new FileReader();
          reader.addEventListener("load", function (e) {
            const readerTarget = e.target;
            const img = document.createElement("img");
            img.src = readerTarget.result;
            img.classList.add("picture__img");
            pictureImage.innerHTML = "";
            pictureImage.appendChild(img);
          });

          reader.readAsDataURL(file);
        } else {
          //   console.log("else", file)
          pictureImage.innerHTML = pictureImageTxt;
        }
      });
    }, 500);
  }, []);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  //certificate
  useMemo(() => {
    setTimeout(() => {
      document.querySelectorAll(".drop-zone__input").forEach((inputElement) => {
        const dropZoneElement = inputElement.closest(".drop-zone");

        dropZoneElement.addEventListener("click", (e) => {
          inputElement.click();
        });

        inputElement.addEventListener("change", (e) => {
          if (inputElement.files.length) {
            updateThumbnail(dropZoneElement, inputElement.files[0]);
          }
        });

        dropZoneElement.addEventListener("dragover", (e) => {
          e.preventDefault();
          dropZoneElement.classList.add("drop-zone--over");
        });

        ["dragleave", "dragend"].forEach((type) => {
          dropZoneElement.addEventListener(type, (e) => {
            dropZoneElement.classList.remove("drop-zone--over");
          });
        });

        dropZoneElement.addEventListener("drop", (e) => {
          e.preventDefault();

          if (e.dataTransfer.files.length) {
            inputElement.files = e.dataTransfer.files;
            updateThumbnail(dropZoneElement, e.dataTransfer.files[0]);
          }

          dropZoneElement.classList.remove("drop-zone--over");
        });
      });

      /**
       * Updates the thumbnail on a drop zone element.
       *
       * @param {HTMLElement} dropZoneElement
       * @param {File} file
       */
      function updateThumbnail(dropZoneElement, file) {
        setFileItem(file);
        uploadDoc("doc", file);
        let thumbnailElement =
          dropZoneElement.querySelector(".drop-zone__thumb");

        // First time - remove the prompt
        if (dropZoneElement.querySelector(".drop-zone__prompt")) {
          // dropZoneElement.querySelector(".drop-zone__prompt").remove();
        }

        // First time - there is no thumbnail element, so lets create it
        if (!thumbnailElement) {
          thumbnailElement = document.createElement("div");
          thumbnailElement.classList.add("drop-zone__thumb");
          dropZoneElement.appendChild(thumbnailElement);
        }

        thumbnailElement.dataset.label = file.name;

        // Show thumbnail for image files
        if (file.type.startsWith("image/")) {
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            thumbnailElement.style.backgroundImage = `url('${reader.result}')`;
          };
        } else {
          thumbnailElement.style.backgroundImage = null;
        }
      }
    }, 501);
  }, [show]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-6 col-lg-6">
              <h5 className="mb-0">{t("Update Worker Details")}</h5>
            </div>

            <div className="col-6 col-md-6 col-lg-6 text-right"></div>
          </div>
        </div>
        <div className="card-body profile">
          <Form noValidate validated={validated}>
            <div className="row g-3 align-items-center mt-1">
              <div className="col-12 col-md-2 col-lg-2">
                {/* <img className="Rectangle32 mb-2" src={data.imageUrl} /> <br /> */}
                <label
                  class="picture"
                  for="picture__input"
                  tabIndex="0"
                  style={
                    errors.image !== "" && data.image === ""
                      ? { border: "2px solid red" }
                      : null
                  }
                >
                  <span class="picture__image"></span>
                </label>
                <input
                  type="file"
                  ref={formRefs.image}
                  name="picture__input"
                  id="picture__input"
                  accept="image/png, image/jpeg, image/jpg"
                >
                  {/* {data.imageUrl} */}
                </input>
                <p
                  style={{
                    color: "#dc3545",
                    fontSize: ".875em",
                    fontFamily: "Poppins, sans-serif",
                    marginTop: ".25rem",
                  }}
                >
                  {errors.image}
                </p>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-2">
              <div className="col-12 col-md-12 col-lg-12">
                <label>1. {t("Personal_Information")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom01">
                  <Form.Label className="text-gray mb-2">
                    {t("Full name")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter full name"
                    name="name"
                    ref={formRefs.name}
                    value={data.name}
                    required={errors.name !== ""}
                    isInvalid={errors.name !== ""}
                    onChange={(e) => setValue(e)}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {errors.name}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom02">
                  <Form.Label className="text-gray mb-2">
                    {t("Email address")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Email address"
                    name="email"
                    ref={formRefs.email}
                    value={data.email}
                    onChange={(e) => setValue(e)}
                    // required={errors.email !== ""}
                    // isInvalid={errors.email !== ""}
                    pattern=".+@[^@\s]+\.[^.\s]+"
                    disabled
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "5px" }}
                  >
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom03">
                  <Form.Label className="text-gray mb-2">
                    {t("Phone No")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter phone number"
                    name="phoneNo"
                    ref={formRefs.phoneNo}
                    value={data.phoneNo}
                    maxLength={15}
                    onChange={(e) => setValue(e)}
                    required={errors.phoneNo !== ""}
                    isInvalid={errors.phoneNo !== ""}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.phoneNo}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom04">
                  <Form.Label className="text-gray mb-2">
                    {t("Date_of_Birth")}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter date of birth"
                    name="dob"
                    ref={formRefs.dob}
                    value={data.dob}
                    onChange={(e) => setValue(e)}
                    min="1900-01-01"
                    max={eighteenYearsAgo.toISOString().split("T")[0]} // Set max date to 18 years ago
                    required={errors.dob !== ""}
                    isInvalid={errors.dob !== ""}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.dob}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom05">
                  <Form.Label className="text-gray mb-2">
                    {t("Address")}
                  </Form.Label>
                  <Form.Control
                    // required
                    as="textarea"
                    type="text"
                    placeholder="Enter address"
                    name="address"
                    ref={formRefs.address}
                    value={data.address}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom06">
                  <Form.Label className="text-gray mb-2">
                    {t("Citizenship")}
                  </Form.Label>
                  <Form.Control
                    //  // required
                    type="text"
                    placeholder="Enter citizenship"
                    name="citizenship"
                    ref={formRefs.citizenship}
                    value={data.citizenship}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom07">
                  <Form.Label className="text-gray mb-2">
                    {t("Security_Number")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Social security number"
                    name="ssn"
                    ref={formRefs.ssn}
                    value={data.ssn || ""} // Ensure the value is never undefined
                    onChange={(e) => setValue(e)}
                    required
                    isInvalid={errors.ssn !== ""}
                    maxLength={16}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.ssn}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label>2. {t("Employment_Information")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom08">
                  <Form.Label className="text-gray mb-2">
                    {t("Employee_ID")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Employee Id"
                    name="employeeId"
                    ref={formRefs.employeeId}
                    value={data.employeeId}
                    onChange={(e) => setValue(e)}
                    maxLength={10}
                    disabled
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom09">
                  <Form.Label className="text-gray mb-2">
                    {t("Worker Skill")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Stack
                    spacing={3}
                    sx={{ width: "100%" }}
                    style={
                      errors.skillId !== "" && data.skillId === ""
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : null
                    }
                  >
                    <Autocomplete
                      disablePortal
                      id="combo-box-demo"
                      ref={formRefs.skillId}
                      value={
                        skill?.find((option) => option.id === data.skillId) ||
                        null
                      } // Match by ID
                      options={skill}
                      getOptionLabel={(option) => option.name}
                      sx={{ width: "100%" }}
                      filterSelectedOptions
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      onChange={(event, value) => {
                        setData({ ...data, skillId: value?.id || "" }); // Set ID or empty string
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("Select worker skill")}
                          name="skills"
                        />
                      )}
                    />
                  </Stack>
                  {/* <Form.Control.Feedback style={{ marginBottom: "15px" }}>
                    {" "}
                    {errors.skillId}
                  </Form.Control.Feedback> */}
                  <p
                    style={{
                      color: "#dc3545",
                      fontSize: ".875em",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: ".25rem",
                    }}
                  >
                    {errors.skillId}
                  </p>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom10">
                  <Form.Label className="text-gray mb-2">
                    {t("Union Membership")}
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter union membership"
                    name="unionMembership"
                    ref={formRefs.unionMembership}
                    value={data.unionMembership}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom11">
                  <Form.Label className="text-gray mb-2">
                    {t("Hire_date")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Enter hire date"
                    name="hireDate"
                    ref={formRefs.hireDate}
                    value={data.hireDate}
                    onChange={(e) => setValue(e)}
                    required={errors.hireDate !== ""}
                    isInvalid={errors.hireDate !== ""}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.hireDate}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom12">
                  <Form.Label className="text-gray mb-2">
                    {t("Employment_Type")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter employment type"
                    name="employmentType"
                    ref={formRefs.employmentType}
                    value={data.employmentType}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom13">
                  <Form.Label className="text-gray mb-2">
                    {t("Pay Rate")} <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Pay rate"
                    name="payRate"
                    ref={formRefs.payRate}
                    value={data.payRate}
                    onChange={(e) => setValue(e)}
                    required={errors.payRate !== ""}
                    isInvalid={errors.payRate !== ""}
                    maxLength={4}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.payRate}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label>3. {t("Language_Skills")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom14">
                  <Form.Label className="text-gray mb-2">
                    {t("Select languages")}{" "}
                    <span className="text-danger">*</span>{" "}
                  </Form.Label>
                  <Stack
                    spacing={3}
                    sx={{ width: "100%" }}
                    style={
                      errors.languageSkills !== "" &&
                      data.languageSkills.length === 0
                        ? { border: "1px solid red", borderRadius: "5px" }
                        : null
                    }
                  >
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      ref={formRefs.languageSkills}
                      value={
                        Array.isArray(data.languageSkills)
                          ? data.languageSkills
                          : []
                      }
                      options={languages}
                      getOptionLabel={(option) => option.name}
                      filterSelectedOptions
                      onChange={(event, value) => {
                        setData({ ...data, languageSkills: value });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="skills"
                          placeholder={t("Select languages")}
                        />
                      )}
                    />
                  </Stack>
                  {/* <Form.Control.Feedback style={{ marginBottom: "15px" }}>
                    {" "}
                    {errors.languageSkills}
                  </Form.Control.Feedback> */}
                  <p
                    style={{
                      color: "#dc3545 ",
                      fontSize: ".875em",
                      fontFamily: "Poppins, sans-serif",
                      marginTop: ".25rem",
                    }}
                  >
                    {errors.languageSkills}
                  </p>
                </Form.Group>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label>4. {t("Certifications and Qualifications")}</label>
              </div>
            </div>
            <div className="row align-items-center mt-4">
              <div className="col-12 col-md-6 col-lg-6 mb-4 ">
                <label>
                  {t("License")}( {t("If any")} )
                </label>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4 text-right">
                <Button
                  variant="outlined"
                  type="button"
                  onClick={() => setShow(!show)}
                >
                  {t("Add Certification")}
                </Button>
              </div>
            </div>
            {show ? (
              <div className="row">
                <div className="col-12 col-md-12 col-lg-12 mb3">
                  <div className="card radius-0">
                    <div className="card-body border align-items-center p-lg-5">
                      <div className="col-12 col-md-4 col-lg-6 mb-3">
                        <Row>
                          <Form.Group
                            controlId="validationCustom15"
                            as={Col}
                            md="6"
                          >
                            <Form.Label className="text-gray mb-2">
                              {t("Certifications name")}
                            </Form.Label>
                            <Form.Control
                              // // required
                              type="text"
                              placeholder="Enter certifications name"
                              value={fileName}
                              onChange={(e) => setFileName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group
                            controlId="validationCustom11"
                            as={Col}
                            md="6"
                          >
                            <Form.Label className="text-gray mb-2">
                              {t("Validity date")} {validityDate}
                            </Form.Label>
                            <Form.Control
                              type="date"
                              placeholder="Enter validity date"
                              isInvalid={errors.validityDate !== ""}
                              value={validityDate}
                              onChange={handleDateChange}
                            />
                            <Form.Control.Feedback
                              type="invalid"
                              style={{ marginBottom: "15px" }}
                            >
                              {" "}
                              {errors.validityDate}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Row>
                      </div>

                      <div className="row">
                        <div className="col-12 col-md-4 col-lg-6 mb-3">
                          <div class="drop-zone">
                            {document.querySelector(".drop-zone__thumb") ==
                            null ? (
                              <span className="drop-zone__prompt">
                                <img src="/assets/plugins/fancy-file-uploader/fancy_upload.png" />
                              </span>
                            ) : null}
                            <input
                              type="file"
                              name="myFile"
                              class="drop-zone__input"
                              accept=".pdf, .doc, .docx, image/*"
                            />
                          </div>
                          <br />
                          {showBar ? (
                            <LinearProgress
                              variant="determinate"
                              value={progress}
                            />
                          ) : null}

                          <button
                            variant="contained"
                            className="mt-2 btn btn-outline-secondary btn-blue "
                            ref={saveBtn}
                            onClick={addCertificate}
                          >
                            {t("Add")}
                          </button>
                        </div>
                      </div>
                      <ul style={{ listStyle: "none", margin: 0, padding: 0 }}>
                        {certificatesList.map((item, i) => (
                          <li className="">
                            <div className="card p-3 ">
                              <div className="d-flex justify-content-between">
                                <span>
                                  <b>
                                    {t("Name")}: {item.certificateName}{" "}
                                    <span
                                      style={{
                                        marginLeft: "15px",
                                        marginRight: "15px",
                                        color: "#d3d3d3",
                                      }}
                                    >
                                      |
                                    </span>{" "}
                                    {t("Validity date")}: {item.validityDate}
                                  </b>
                                </span>{" "}
                                <span>
                                  <Button
                                    onClick={() => removeCertificate(item)}
                                  >
                                    <DeleteIcon color="error" />
                                  </Button>
                                </span>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label>5. {t("Emergency and Medical Information")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom16">
                  <Form.Label className="text-gray mb-2">
                    {t("Emergency Number")}{" "}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter emergency number"
                    name="emergencyNumber"
                    ref={formRefs.emergencyNumber}
                    value={data.emergencyNumber}
                    onChange={(e) => setValue(e)}
                    required={errors.emergencyNumber !== ""}
                    isInvalid={errors.emergencyNumber !== ""}
                    maxLength={15}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.emergencyNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom17">
                  <Form.Label className="text-gray mb-2">
                    {t("Blood Type")}
                  </Form.Label>
                  <Form.Select
                    aria-label="Default select example"
                    placeholder="Select blood type"
                    className=" mb-3 height-50"
                    name="bloodType"
                    onChange={(e) => setValue(e)}
                    value={data.bloodType}
                  >
                    <option value="">{t("Select Blood Type")}</option>
                    <option value="A+">A+</option>
                    <option value="A-">A-</option>
                    <option value="B+">B+</option>
                    <option value="B-">B-</option>
                    <option value="AB+">AB+</option>
                    <option value="AB-">AB-</option>
                    <option value="O+">O+</option>
                    <option value="O-">O-</option>
                  </Form.Select>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom18">
                  <Form.Label className="text-gray mb-2">
                    {t("Allergies")} ( {t("If any")} )
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter Allergies"
                    name="allergiesName"
                    value={data.allergiesName}
                    onChange={(e) => setValue(e)}
                  />
                </Form.Group>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label>6. {t("Bank_Account_Details")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom19">
                  <Form.Label className="text-gray mb-2">
                    {t("Account_holder_name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter account holder name"
                    name="holderName"
                    ref={formRefs.holderName}
                    value={data.holderName}
                    onChange={(e) => setValue(e)}
                    maxLength={150}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom20">
                  <Form.Label className="text-gray mb-2">
                    {t("Bank_Name")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter bank name"
                    name="bankName"
                    ref={formRefs.bankName}
                    value={data.bankName}
                    onChange={(e) => setValue(e)}
                    maxLength={150}
                  />
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-3">
                <Form.Group controlId="validationCustom21">
                  <Form.Label className="text-gray mb-2">
                    {t("Account number")}
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter account number"
                    name="accountNumber"
                    ref={formRefs.accountNumber}
                    value={data.accountNumber}
                    onChange={(e) => setValue(e)}
                    maxLength={25}
                    isInvalid={!!errors.accountNumber}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.accountNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>

              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom22">
                  <Form.Label className="text-gray mb-2">
                    {t("Account_Type")}
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter account type"
                    name="accountType"
                    ref={formRefs.accountType}
                    value={data.accountType}
                    onChange={(e) => setValue(e)}
                    maxLength={100}
                  />
                </Form.Group>
              </div>
              <div className="col-12 col-md-4 col-lg-4 mb-4">
                <Form.Group controlId="validationCustom23">
                  <Form.Label className="text-gray mb-2">
                    {t("IBAN number")}
                  </Form.Label>
                  <Form.Control
                    // required
                    type="text"
                    placeholder="Enter IBAN Number"
                    name="ibanNumber"
                    ref={formRefs.ibanNumber}
                    value={data.ibanNumber}
                    onChange={(e) => setValue(e)}
                    maxLength={34}
                    isInvalid={!!errors.ibanNumber}
                  />
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ marginBottom: "15px" }}
                  >
                    {" "}
                    {errors.ibanNumber}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
            </div>
          </Form>

          <div className="row">
            <div className="col-12 col-md-6 col-lg-6 mb-6 mt-4">
              {load ? (
                <CircularProgress sx={{ color: "#000" }} />
              ) : (
                <div>
                  <Button
                    type="button"
                    size="large"
                    variant="contained"
                    className="me-2"
                    onClick={handleSubmit}
                  >
                    {t("Update")}
                  </Button>
                  <Button
                    variant="outlined"
                    size="large"
                    component={RouterLink}
                    to="/dashboard/company/wokers"
                  >
                    {t("Close")}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default EditWorker;
