import http from "../../../../baseURL/httpcommon";
import authHeader from "../../../../baseURL/authHeader";

const workerskill = (companyId, page, pageSize, name, sortBy, sortDir) => {
  return http.get(
    `/company/company/${companyId}/skills?pageNumber=${page}&pageSize=${pageSize}&name=${name}&sortBy=${sortBy}&sortDir=${sortDir}`
  );
};
const workerskillSave = (data) => {
  return http.post("/company/create/skill", data, {
    headers: {
      ...authHeader(),
    },
  });
};

const changeSkillStatus = (id, status) => {
  return http.patch(`/company/skills/change-status/${id}?status=${status}`);
};

const workerSkillUpdate = (data) => {
  debugger;
  return http.put(`/company/skills/update/${data.id}`, data, {
    headers: {
      ...authHeader(),
    },
  });
};

const SkillService = {
  workerskill,
  changeSkillStatus,
  workerskillSave,
  workerSkillUpdate,
};

export default SkillService;
