import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";

const RefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div class="container pt-3 pb-4">
        <Link component={RouterLink} to={"/"}>
          <img
            src="/assets/img/tappme-logo.png"
            alt="policy"
            className="desk_tops"
          />
        </Link>
        <h1>
          <strong style={{ fontSize: "30px" }}>Refund/Return Policy</strong>
        </h1>
        <p className="ms-2">
          Last Updated: <strong>May 12, 2024</strong>
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Bad refund policies are infuriating. You feel like the company is just
          trying to rip you off. We never want our customers to feel that way,
          so our refund policy is simple: If you’re ever unhappy with our
          products for any reason, just contact us{" "}
          <strong>info@tappme.eu</strong> and we'll take care of you.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          We are sorry to hear that you are returning an item. But do not worry,
          we, at{" "}
          <a href="https://tappme.se/" target="_blank">
            TAPP ME
          </a>
          , will guide and assist you, in every way possible!
        </p>
        <h3>
          <strong>Return</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          We want you to be completely satisfied with your purchase. If you have
          any problems with this, you can return the product to us and we will
          either repair/replace it. You are eligible for a exchanges which are
          subject to the below terms.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          All Items are eligible for return and will be processed subject to
          certain conditions.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Items that were marked as a gift when purchased is not allowed to be
          Returned or Exchanged.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          In case of the return of the items, we will cover all costs associated
          with the return.
        </p>
        <h3>
          <strong>Refund</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          In order to be eligible for a refund, you have to return the product
          within 30 days of your purchase. If the product is damaged/spoiled in
          any way, or you have initiated the return after 30 days have passed,
          you will not be eligible for a refund.
        </p>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          In order for the product to be eligible for a refund, make sure these
          conditions are met:
        </p>
        <ul
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
            marginRight: "5rem",
          }}
        >
          <li>Product must have the receipt or proof of purchase</li>
          <li>
            The customer must explain exactly what he was not satisfied with and
            why is requesting a refund
          </li>
        </ul>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          Once the item have been received and checked by our staff, a refund
          will be authorised by the same method that the payment was made.
          Depending on your financial institution, refunds can take up to 60
          days to be credited to your original payment method. In all cases we
          have the right to suspend the refund until the items are received back
          and inspected.
        </p>
        <h3>
          <strong>Contact us</strong>
        </h3>
        <p
          className="ms-3"
          style={{
            fontWeight: "700",
            fontFamily: "Sans-Serif",
          }}
        >
          For any questions or queries regarding this Refund Policy, please feel
          free to contact us:
        </p>
        <div className="ms-3">
          <strong>
            <a
              href="mailto:info@tappme.eu"
              style={{ color: "blue" }}
              target="_blank"
            >
              info@tappme.eu
            </a>
          </strong>
        </div>
      </div>
    </>
  );
};

export default RefundPolicy;
