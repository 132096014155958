import React, { useEffect, useMemo, useRef, useState } from "react";
import { FaDownload } from "react-icons/fa";
import { Form, Row, Col, CloseButton } from "react-bootstrap";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import { jwtDecode } from "jwt-decode";
import ProfileServices from "./services";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import { Button } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

// import Modal from "react-bootstrap/Modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { useLocation } from "react-router-dom";
import { Chip } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ViewWokers = () => {
  const location = useLocation();
  const { t } = useTranslation();
  console.log("ViewWokers", location.state);

  const [data, setData] = useState({
    name: location.state.data.name,
    phoneNo: location.state.data.phoneNo,
    email: location.state.data.email,
    dob: location.state.data.dob,
    citizenship: location.state.data.citizenship,
    ssn: location.state.data.ssn,
    employeeId: location.state.data.userProfile.employeeId,
    employmentType: location.state.data.userProfile.employmentType,
    hireDate: location.state.data.userProfile.hireDate,
    imageUrl: location.state.data.imageUrl,
    imageName: location.state.data.imageName,
    languageSkills: location.state.data.languageSkills,
    accountHolderName: location.state.data.userProfile.bankDetails.holderName,
    bankName: location.state.data.userProfile.bankDetails.bankName,
    accountNumber: location.state.data.userProfile.bankDetails.accountNumber,
    accountType: location.state.data.userProfile.bankDetails.accountType,
    address: location.state.data.userProfile.address,
    certificates: location.state.data.userProfile.certificates,
    skills:
      location.state.data.userProfile.skills !== null
        ? location.state.data.userProfile.skills
        : "",
    unionMembership: location.state.data.userProfile.unionMembership,
    payRate: location.state.data.userProfile.payRate,
    emergencyNumber: location.state.data.userProfile.emergencyNumber,
    bloodType: location.state.data.userProfile.bloodType,
    allergiesName: location.state.data.userProfile.allergiesName,
  });

  const [message, setMessage] = useState();

  const [severity, setSeverity] = useState("error");
  const [show, setShow] = useState();
  const [showCertification, setShowCertification] = useState(false);
  const [open, setOpen] = useState(false);
  //fetch profile Id
  const token = localStorage.getItem("token");
  const decodedToken = jwtDecode(token);
  const profileId = decodedToken.id;

  console.log("profileId", location);
  //fetch profile data
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className="card-header bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-4 col-lg-4">
              <h5 className="mb-0">{t("View Worker Details")}</h5>
            </div>
            {/* <div className="col-6 col-md-10 col-lg-10 text-right">
                            <button className="btn btn-outline-secondary btn-blue font-14 toggle" type="button" ><i className="fadeIn animated bx bx-pencil"></i> Edit</button>
                        </div> */}

            <Col md={8} lg={8} className="text-right">
              {/* <Link
                className="btn btn-outline-secondary btn-blue font-14 ms-2"
                type="button"
                to="/dashboard/company/editworker"
                state={location.state.data}
              >
                <i className="fadeIn animated bx bx-pencil"></i> Edit Profile
              </Link> */}
              <Button
                variant="outlined"
                to="/dashboard/company/editworker"
                state={location.state.data}
                component={RouterLink}
              >
                <i className="fadeIn animated bx bx-pencil" />
                {"  "}
                {t("Edit Profile")}
              </Button>
            </Col>
          </div>
        </div>
        <div className="card-body profile">
          <Row className=" mt-1">
            <Col xs={12} md={3} lg={3}>
              <div className="profilePicture">
                <img
                  className="Rectangle32 mb-2"
                  src={data.imageUrl}
                  alt="Profile"
                />
              </div>
              <br />
            </Col>

            <input
              type="file"
              //  ref={fileInputRef}
              style={{ display: "none" }}
              //  onChange={handleLogoChange}
            />
          </Row>

          {/* <Row className="g-3 align-items-center mb-3 mt-2">
            <label style={{ marginTop: "30px" }}> Personal Information </label>
          </Row> */}

          <Form>
            <div className="row g-3 align-items-center mb-3 mt-2">
              <div className="col-12 col-md-8 col-lg-8 mb-1 w-10">
                <label className="text-dark">
                  {" "}
                  {t("Personal_Information")}
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark ">{t("Name")}</span>
                  <span className="font-14 text-gray">{data.name}</span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Phone No")}</span>
                  <span className="font-14 text-gray">{data.phoneNo}</span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Address")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.address && data.address.length > 30
                      ? data.address.substring(0, 30) + "..."
                      : data.address}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Email")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.email}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Date_of_Birth")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.dob}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Citizenship")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.citizenship}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Security_Number")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.ssn}
                  </span>
                </div>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label className="text-dark">
                  {t("Employment_Information")}
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Employee_ID")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.employeeId}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Employment_Type")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.employmentType}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Hire_date")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.hireDate}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Worker Skill")}</span>
                  <span className="font-14 text-gray text-right">
                    {data?.skills?.name}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Union Membership")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.unionMembership}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Pay Rate")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.payRate}
                  </span>
                </div>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label className="text-dark">{t("Language_Skills")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-3 col-lg-12 mb-4">
                {data.languageSkills.map((item) => (
                  <Chip label={item.name} />
                ))}
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label className="text-dark">{t("Certificates")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-12 col-lg-12  pb-1 mb-3">
                <div className="card radius-15">
                  <div className="card-body card-border align-items-center">
                    {Array.isArray(data.certificates) &&
                    data.certificates.length > 0 ? (
                      data.certificates.map((item, index) => (
                        <div key={index} className="row mb-3">
                          <div className="col-12 col-md-6">
                            <span className="text-primary font-18">
                              {item.certificateName}
                            </span>
                            <span className="text-primary font-18">
                              {"  "} {item.validityDate}
                            </span>
                          </div>
                          <div className="col-12 col-md-6 text-end">
                            <a
                              href={item.documents.documentUrl}
                              target="_blank"
                            >
                              <FaDownload className="text-primary font-20 ml-4 cursor-pointer" />
                            </a>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>{t("No_records_found")}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label className="text-dark">
                  {t("Emergency and Medical Information")}
                </label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Emergency Number *")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.emergencyNumber}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Blood Type")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.bloodType}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Allergies")} ( {t("If any")} )
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.allergiesName}
                  </span>
                </div>
              </div>
            </div>

            <div className="row"></div>

            <div className="row g-3 align-items-center mb-3 mt-1">
              <div className="col-12 col-md-6 col-lg-6">
                <label className="text-dark">{t("Bank_Account_Details")}</label>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Account_holder_name")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.accountHolderName}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Bank_Name")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.bankName}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Account_Details")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.accountNumber}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">{t("Account_Type")}</span>
                  <span className="font-14 text-gray text-right">
                    {data.accountType}
                  </span>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6 col-lg-6 mb-4">
                <div className="border-bottom2 height-50 d-flex justify-content-between align-items-center">
                  <span className="font-14 text-dark">
                    {t("Branch_Address")}
                  </span>
                  <span className="font-14 text-gray text-right">
                    {data.address && data.address.length > 30
                      ? data.address.substring(0, 30) + "..."
                      : data.address}
                  </span>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mb-4"></div>
            </div>
          </Form>
        </div>
      </div>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default ViewWokers;
