import { useEffect, useState } from "react";
import RequestApi from "./services";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";

const CompanyRequest = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const [message, setMessage] = useState(true);
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };
  const schema = {
    requestId: "",
    name: "",
    emailAddress: "",
    phoneNo: "",
    ssn: "",
    companyName: "",
    languageSkills: "",
    emergencyNumber: "",
    allData: "",
  };
  const [getData, setGetdata] = useState(schema);
  const handleShow = (
    requestId,
    name,
    email,
    phoneNo,
    ssn,
    company,
    languageSkills,
    emergencyNumber,
    allData
  ) => {
    setShow(true);
    setGetdata({
      ...getData,
      requestId: requestId,
      fullName: name,
      emailAddress: email,
      phoneNo: phoneNo,
      ssn: ssn,
      company: company,
      languageSkills: languageSkills,
      emergencyNumber: emergencyNumber,
      allData: allData,
    });
  };

  const [timeZone, setTimeZone] = useState("");
  useEffect(() => {
    // Fetch the time zone
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    setTimeZone(tz);
  }, []);

  useEffect(() => {
    // Fetch dashboard data when timeZone is available
    if (timeZone) {
      getWorkerRequest();
    }
  }, [timeZone]); //

  function getWorkerRequest() {
    debugger;
    RequestApi.getWorkerRequest(timeZone).then((respons) => {
      setData(respons.data.data);
      console.log("getWorkerRequest", respons.data.data);
    });
  }

  function workerAprroval(requestId, status) {
    navigate("/dashboard/company/addwoker", {
      state: { ...getData.allData, requestId: requestId, status: status },
    });
  }
  function workerReject(requestId, status) {
    debugger;
    RequestApi.workerAprroval(requestId, status).then((response) => {
      console.log(response);
      setMessage(response.data.message);
      setSeverity("success");
      setOpen(true);
      setShow(false);
      getWorkerRequest();
    });
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const formatDate = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 because months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      <div className="card radius-10 p-4 px-3">
        <div className=" p-3 pt-0 bg-transparent border-0">
          <div className="row g-3 align-items-center">
            <div className="col-6 col-md-6 col-lg-3">
              <h5 className="mb-0 font-18">{t("Worker_Requests")}</h5>
              {/* <input type="file" name="fileInput" id="fileInput" />
                     <button type="button" onClick={testApi}>Upload</button> */}
            </div>

            <div className="col-6 col-md-6 col-lg-9">
              {/* <div className="text-right">
                <button
                  className="btn btn-outline-secondary dropdown-toggle btn-blue px-3"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  All
                </button>
                <ul className="dropdown-menu dropdown-menu-end border-b">
                  <li>
                    <a className="dropdown-item" href="#">
                      All
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Approved
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Rejected
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item" href="#">
                      Pending
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col py-2">
              {data.length > 0 ? (
                data.map((item) => (
                  <div className="card radius-15">
                    <div className="card-body card-border align-items-center">
                      <div className="float-end text-dark">
                        <button
                          type="button"
                          className="btn btn-primary px-3 font-14"
                          onClick={() =>
                            handleShow(
                              item.requestId,
                              item.fullName,
                              item.emailAddress,
                              item.phoneNo,
                              item.ssn,
                              item.companyResponse.companyName,
                              item.languageSkills,
                              item.emergencyNumber,
                              item
                            )
                          }
                        >
                          {t("View Details")}
                        </button>
                      </div>

                      <p className="card-text pt-1">
                        {item.fullName} has requested for credentials to login
                        on {formatDate(item?.createdAt)}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Request Found</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Request for Credential")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-3">
              <div className="px-4 pt-3 text-center">
                <h6 className="font-20 text-dark  text-center">
                  {getData.fullName}
                </h6>
              </div>
              <div className="px-lg-4 pt-4 px-3">
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">{t("Full name")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark ">
                        {" "}
                        {getData.fullName}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">{t("Email address")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark ">
                        {" "}
                        {getData.emailAddress}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">{t("Phone No")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark">
                        {getData.phoneNo}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">
                      {t("Personal/Social_security_number")}
                    </label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark ">{getData.ssn}</span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">{t("Company_name")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark ">
                        {getData.company}
                      </span>
                    </div>
                  </div>

                  <div className="col-md-6 col-12  mb-3">
                    <label className="font-14">{t("Emergency Number")}</label>
                    <div className="border-bottom2 height-50">
                      <span className="font-14 text-dark text-right ">
                        {" "}
                        {getData.emergencyNumber}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="row text-center mt-4">
                  <div className="col-md-12 col-12  mb-3">
                    {/* <Link to="/dashboard/company/addwoker" state={{ ...getData.allData }} className="btn btn-primary px-3 px-3 w-50">Register Employee</Link> */}
                    <Button
                      className="btn btn-primary px-3 px-3 w-50"
                      onClick={() => workerAprroval(getData.requestId, true)}
                    >
                      {t("Register Worker")}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            {t("Close")}
          </Button>
          <Button
            variant="danger"
            onClick={() => workerReject(getData.requestId, false)}
          >
            {t("Reject")}
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
          <div className="modal-content">
            <div className="modal-header border-0 mb-0 p-3 pb-0">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body font-20 text-dark px-4"></div>
          </div>
        </div>
      </div>
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          open={open}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={severity}
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Stack>
    </>
  );
};

export default CompanyRequest;
